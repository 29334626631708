/*
Author:      Zachary Thomas
Created:     3/8/2022
Modified:    3/10/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.history-data-block {
  display: inline-block;
  overflow-wrap: break-word;
  width: 100%;
  line-height: 2rem;

  @include media-breakpoint-up(md) {
    width: 37%;
  }
}

.json-query-display {
  border-style: solid;
  border-width: 2px;
  border-color: var(--border);
  background-color: var(--background-far);
  max-height: 750px;
}

.history-top-level-backdrop {
  z-index: 1055 !important;
}