/*
Author:      Zachary Thomas
Created:     2/22/2022
Modified:    3/7/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.iot-resource {
  cursor: pointer;
}

.iot-resource:hover {
  background-color: var(--resource-hover);
}

.iot-resource.selected {
  background-color: var(--resource-select);
}