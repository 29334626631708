/*
Author:      Zachary Thomas
Created:     6/2/2021
Modified:    6/2/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.page-title {
  color: var(--page-title);
  text-align: center;
  font-weight: 600;
  font-size: 3.5rem;
}