/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    5/14/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.spinner-border {
  color: var(--dark);
}

.spin-loader {
  visibility: visible;
  position: fixed;
  margin-left: -75px;
  margin-bottom: 75px;
  left: 50%;
  bottom: 50%;
  width: 0;
  height: 0;
  z-index: 9999;
}