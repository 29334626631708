/*
Author:      Zachary Thomas
Created:     2/24/2022
Modified:    2/24/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.iot-device-history {
  .empty-list {
    font-size: 1rem;
    background-color: var(--background-close);
    border-color: var(--border);
    border-width: 1px;
    border-style: solid;
    text-align: center;
  }
}