/*
Author:      Zachary Thomas
Created:     3/14/2022
Modified:    3/16/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.tab-list-container.container {
  padding: 0 12px;

  .container-tab.dev {
    background-color: var(--tab-inactive-dev);
  }

  .container-tab.prod {
    background-color: var(--tab-inactive-prod);
  }

  .container-tab {
    border-radius: 0;
    font-weight: 600;
    border-width: 0;

    .tab-name {
      font-size: 1.25rem;
      color: var(--title);
      display: none;
    
      @include media-breakpoint-up(xxl) {
        display: inline;
      }
    }

    .tab-short-name {
      font-size: 1.25rem;
      color: var(--title);
      display: inline;

      @include media-breakpoint-up(xxl) {
        display: none;
      }
    }
  }

  .btn.container-tab.dev:hover {
    background-color: var(--tab-hover-dev);
  }

  .btn.container-tab.prod:hover {
    background-color: var(--tab-hover-prod);
  }

  .btn.container-tab.tab-active {
    outline: none;
    box-shadow: none;
  }

  .btn.container-tab.tab-active.dev {
      background-color: var(--tab-active-dev);
  }

  .btn.container-tab.tab-active.prod {
      background-color: var(--tab-active-prod);
  }
}


.btn:focus,.btn:active {
  outline: none !important;
  box-shadow: none;
}