/*
Author:      Zachary Thomas
Created:     2/18/2022
Modified:    2/18/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.page-iot-find-devices {
  .search-query-container {
    background-color: var(--form-container);
    border-color: var(--border-light);
    border-width: 2px;
    border-style: solid;
  }

  .search-devices-btn {
    font-size: 1.5rem;
  }

  .list-item {
    font-size: 1.15rem;
    border-color: var(--border);
    border-width: 2px;
    border-style: solid;
    background-color: var(--background-close);
  }

  .link {
    color: var(--link);
    font-weight: bold;
  }

  .link:hover {
    text-decoration: underline;
  }
}