/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    3/2/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.navbar-custom {
  .navbar-brand {
    font-weight: bold;
    color: var(--title);
    background: var(--logo-background);
  }
  
  .navbar-brand:hover {
    color: var(--title-hover);
  }

  .collapse.navbar-collapse{
    justify-content: end;
  }

  .control-label {
    color: var(--title);
  }
}
