/*
Author:      KC Willard
Created:     1/11/2022
Modified:    1/11/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

a:hover, a:visited, a:link, a:active
{
    text-decoration: none;
    color: inherit;
}