/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    4/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.bread-crumbs {
  text-align: left;
  font-weight: bold;
  font-size: 1rem;

  .link-crumb {
    font-size: 1rem;
    color: var(--link);
  }

  .link-crumb:hover {
    text-decoration: underline;
  }
}