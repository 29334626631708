/*
Author:      Grayson Fleming
Created:     3/3/2022
Modified:    3/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
.endpoint-form{
    .control-label {
        color: var(--bs-body-color)
    }     
}

#endpointSelect:focus{
    color: #212529;
    background-color: #fff;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}