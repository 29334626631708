/*
Author:      KC Willard
Created:     10/19/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.template-form {
  .form-check-big {
    display: block;
    width: 38px;
    height: 38px;
    cursor: pointer;
  }
}