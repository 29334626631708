/*
Author:      Zachary Thomas
Created:     3/7/2022
Modified:    3/14/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.resource-top-level-backdrop {
  z-index: 1055 !important;
}

.no-files-message {
  font-size: 1.25rem;
  text-align: center;
}