/*
Author:      Zachary Thomas
Created:     4/9/2021
Modified:    5/20/2022

Copyright 2021-2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.data-query-tools {
  margin: 0 auto;

  .data-query-form-select {
    border: 1px solid var(--border);
    border-radius: .25rem;
    height: 75%;
    width: 100%;
  }
  
  .data-query-button {
    width: 100%;
    height:75%;
    margin-top: 24px;
    color: var(--title);
    background-color: var(--border-dark);
    border-color: var(--border-dark);
  }
  
  .data-query-button.btn:hover {
    border-color: var(--text-inactive);
  }
  
  .control-label{
    color: white;
    text-align: center;
    font-size:smaller;  
  }
  
}

