/*
Author:      Tyler Petty
Created:     6/8/2022
Modified:    6/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.page-note-template {
    .mini-container {
        display: inline-flex;
    }

    .form-check-big {
        display: block;
        width: 38px;
        height: 38px;
        cursor: pointer;
    }
}