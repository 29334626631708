/*
Author:      Zachary Thomas
Created:     2/24/2022
Modified:    3/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.iot-device-configuration {
  .select-resource-btn {
    border-radius: 5% 0 0 5%;
  }

  .label-col {
    height: 38px;
  }

  .select-resource-label {
    cursor: pointer;
    border-radius: 0 3% 3% 0;
    padding-left: 1rem;
    border-style: solid;
    border-width: 1px;
    border-color: var(--border);
    height: 100% !important;

    span {
      position: relative;
      top: 20%;
    }
  }

  .device-container {
    background-color: var(--background-close);
    border-color: var(--border);
    border-width: 1px;
    border-style: solid;
    min-height: 355px;
  }

  .submit-btn, .submit-btn:hover, .submit-btn:focus {
    color: var(--title);
  }
}