/*
Author:      KC Willard
Created:     2/25/2022
Modified:    3/17/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/
.process-form{
    .control-label {
        color: var(--bs-body-color)
    }
    label {
        margin-bottom: .15rem;
    }     
}
#toggleIsEnabledSwitch {    
    .form-check-input:checked{
        background-color: green;
    }
    .form-check-input{
        background-color: red;
        fill: red;
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    }
}


