/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    6/4/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.general-footer {
  background-color: var(--primary-background);
  color: var(--title);
}