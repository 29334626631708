/*
Author:      Zachary Thomas
Created:     8/26/2021
Modified:    8/26/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.toast {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 500;
  opacity: 1 !important;
  cursor: pointer;
}

.toast.success-toast .toast-header {
  background-color: var(--light-alert-success);
}

.toast.success-toast {
  background-color: var(--alert-success);
}

.toast.error-toast .toast-header {
  background-color: var(--light-alert-error);
}

.toast.error-toast {
  background-color: var(--alert-error);
}