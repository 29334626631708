/*
Author:      Tyler Petty
Created:     6/8/2022
Modified:    6/13/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.list-header {
    background-color: var(--background-close);
    border-color: var(--border);
    border-width: 2px;
    border-style: solid;
    text-align: left;

    .list-title {
        color: var(--title);
        font-weight: bold;
        font-size: 1rem;

        @include media-breakpoint-up(md) {
            font-size: 1.5rem;
        }
    }

    .create-btn {
        font-size: 0.75rem;

        @include media-breakpoint-up(md) {
            font-size: 1rem;
        }
    }
}

.device-list {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: var(--background-close);
    border-color: var(--border);
    border-width: 2px;
    border-style: solid;

    thead {
        position: sticky;
        top: -5px;
        background-color: var(--table-header);
    }

    .list-body {
        background-color: var(--background-close);
        border-color: var(--border);
        border-width: 2px;
        border-style: solid;

        .list-item {
            border-color: var(--border);
            border-style: solid;
            background-color: var(--background-close);

            .delete-device,
            .enable-device {
                width: 25px;
            }

            .form-check-big {
                width: 100%;
                height: 38px;
                cursor: pointer;
            }
        }

        .empty-list {
            font-size: 1rem;
            background-color: var(--background-close);
            border-color: var(--border);
            border-width: 1px;
            border-style: solid;
            text-align: center;

            @include media-breakpoint-up(md) {
                font-size: 1.25rem;
            }
        }
    }

    .list-footer {
        .alert {
            margin: 0;
        }
    }
}