/*
Author:      KC Willard
Created:     10/19/2022
Modified:    10/19/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.template-modal-container {
  .btn {
    font-size: 0.75rem;

    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
  }
}