/*
Author:      Zachary Thomas
Created:     2/28/2022
Modified:    2/28/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/_breakpoints';

.alert-warning {
  font-size: 0.8rem !important;

  @include media-breakpoint-up(sm) {
    font-size: 1rem !important;
  }
}