/*
Author:      Zachary Thomas
Created:     4/6/2022
Modified:    4/6/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

.audience-select {
  select {
    display: inline-block;
    width: 100%;
    padding: .375rem 2.25rem .375rem .75rem;
    line-height: 1.5;
    font-weight: 400;
    color: #212529;
    background-color: var(--background-close);
    border: 1px solid #ced4da;
    border-radius: 0;
    appearance: none;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    font-size: 1.25rem;
  }
}